import {
  useGetVehicleBodyTypesQuery,
  useGetVehicleConditionTypesQuery,
  useGetVehicleTransmissionTypesQuery,
  useGetReportConditionTypesQuery,
  type Report_Condition_Type,
  type Vehicle_Body_Type,
  type Vehicle_Transmission_Type,
  useGetVehicleStatusTypesQuery,
  useGetAppointmentTypesQuery,
  type Vehicle_Status_Type,
  type Vehicle_Condition_Type,
  type Appointment_Type,
  type Payment_Method_Type,
  type Payment_Category_Type,
  type Booking_Status_Type,
  type Auction_Status_Type,
  useGetAuctionStatusTypesQuery,
  Order_By,
  useGetReportCategoriesQuery
} from '~/graphql/types'
import { useQueryLoading } from '@vue/apollo-composable'
import _ from 'lodash'
import { defineStore } from 'pinia'
import type { ReportCategory } from '~/types/inspections'

/**
 * Pinia composable for database and extra type enums
 * @returns Objects containing types
 */
export const useDatabaseTypes = defineStore('types', () => {
  const isLoading = useQueryLoading()

  /*
    VEHICLE TYPES (START)
     */
  const vehicle = ref<{
    transmissionTypes: Vehicle_Transmission_Type[]
    bodyTypes: Vehicle_Body_Type[]
    engineTypes: string[]
    statusTypes: Vehicle_Status_Type[]
    conditionTypes: Vehicle_Condition_Type[]
    fuelTypes: string[]
    assemblyTypes: string[]
    registrationTypes: string[]
    drivetrainTypes: string[]
    colors: string[]
  }>({
    transmissionTypes: [],
    bodyTypes: [],
    get engineTypes() {
      const engines: string[] = []

      for (let i = 1; i <= 15; i += 0.1) {
        engines.push(i.toFixed(1))
      }

      return engines
    },
    statusTypes: [],
    conditionTypes: [],
    fuelTypes: ['Petrol', 'EV', 'Diesel', 'Hybrid'],
    assemblyTypes: ['Locally Built', 'Parallel Import'],
    registrationTypes: ['Private', 'Company'],
    drivetrainTypes: ['2WD (2 Wheel Drive)', '4WD (4 Wheel Drive)', 'AWD (All Wheel Drive)'],
    colors: [
      'Red',
      'Orange',
      'Yellow',
      'Green',
      'Cyan',
      'Blue',
      'Magenta',
      'Purple',
      'White',
      'Black',
      'Grey',
      'Silver',
      'Pink',
      'Maroon',
      'Brown',
      'Beige',
      'Tan',
      'Peach',
      'Lime',
      'Olive',
      'Turquoise',
      'Teal',
      'Navy Blue',
      'Indigo',
      'Violet'
    ]
  })

  /*
    TRANSMISSIONS
    */
  const { result: transmissionsResult } = useGetVehicleTransmissionTypesQuery({
    limit: 10,
    offset: 0,
    orderBy: {}
  })

  // Rehydrate transmissions
  watch(transmissionsResult, () => {
    const result = transmissionsResult.value

    if (result) {
      result.vehicleTransmissionTypes.forEach((transmission) => {
        vehicle.value.transmissionTypes.push(transmission)
      })
    }
  })

  /*
   BODY TYPES
   */
  const { result: bodyTypesResult } = useGetVehicleBodyTypesQuery({
    limit: 100,
    offset: 0,
    orderBy: {}
  })

  // Rehydrate body types
  watch(bodyTypesResult, () => {
    const result = bodyTypesResult.value

    if (result) {
      result.vehicleBodyTypes.forEach((type) => {
        vehicle.value.bodyTypes.push(type)
      })
    }
  })

  /*
    CONDITIONS
    */
  const { result: conditionTypesResult } = useGetVehicleConditionTypesQuery({
    limit: 100,
    offset: 0,
    orderBy: {}
  })

  watch(conditionTypesResult, () => {
    const result = conditionTypesResult.value

    if (result) {
      result.vehicleConditionTypes.forEach((type) => {
        vehicle.value.conditionTypes.push(type)
      })
    }
  })

  const { result: vehicleStatusTypesResult } = useGetVehicleStatusTypesQuery({
    limit: 99,
    offset: 0,
    orderBy: {}
  })

  watch(vehicleStatusTypesResult, () => {
    const result = vehicleStatusTypesResult.value

    if (result) {
      result.vehicleStatusTypes.forEach((type) => {
        vehicle.value.statusTypes.push(type)
      })
    }
  })

  /*
    VEHICLE TYPES (END)
    */

  /*
    INSPECTION TYPES (START)
    */
  const inspection = ref<{
    reportConditions: Report_Condition_Type[]
    reportCategories: ReportCategory[]
    appointmentTypes: Appointment_Type[]
  }>({
    reportConditions: [],
    appointmentTypes: [],
    reportCategories: []
  })

  /*
    CONDITIONS
    */
  const { result: reportConditionTypes } = useGetReportConditionTypesQuery({
    limit: 10,
    offset: 0,
    orderBy: {}
  })

  // Rehydrate report conditions
  watch(reportConditionTypes, () => {
    const result = reportConditionTypes.value

    if (result) {
      result.reportConditionTypes.forEach((type) => {
        inspection.value?.reportConditions.push(type)
      })
    }
  })

  /*
    APPOINTMENT TYPES
    */
  const { result: appointmentTypesResult } = useGetAppointmentTypesQuery({
    limit: 999,
    offset: 0,
    orderBy: {}
  })

  watch(appointmentTypesResult, () => {
    const result = appointmentTypesResult.value

    if (result) {
      result.appointmentTypes.forEach((type) => {
        inspection.value.appointmentTypes.push(type)
      })
    }
  })

  /*
    REPORT CATEGORIES
    */
  const { result: reportCategoriesResult } = useGetReportCategoriesQuery()

  watch(reportCategoriesResult, () => {
    const result = reportCategoriesResult.value
    if (result && result.reportCategories.length > 0) {
      inspection.value.reportCategories = result.reportCategories.map(({ id, key, comment }) => {
        return {
          id,
          key,
          comment: comment || _.capitalize(key)
        }
      })
    }
  })

  /*
    INSPECTION TYPES (END)
    */

  /*
    APPOINTMENT TYPES (START)
    */
  const appointment = ref<{
    bookingStatusTypes: Booking_Status_Type[]
  }>({
    bookingStatusTypes: []
  })

  /*
    BOOKING STATUS TYPES
    */
  // const { result: bookingStatusTypesResult } =
  //     useGetAllBookingStatusTypesQuery();

  // watch(bookingStatusTypesResult, () => {
  //     const result = bookingStatusTypesResult.value;

  //     if (result) {
  //         result.bookingStatusTypes.forEach((type) => {
  //             appointment.value.bookingStatusTypes.push(type);
  //         });
  //     }
  // });

  /*
    FINANCE TYPES (START)
    */
  const finance = ref<{
    paymentMethods: Payment_Method_Type[]
    paymentCategories: Payment_Category_Type[]
  }>({
    paymentMethods: [],
    paymentCategories: []
  })

  /*
    PAYMENT METHODS
    */
  // const { result: paymentMethodsResult } = useGetVehiclePaymentMethodsQuery();

  // watch(paymentMethodsResult, () => {
  //     const result = paymentMethodsResult.value;

  //     if (result) {
  //         result.paymentMethodTypes.forEach((type) => {
  //             finance.value.paymentMethods.push(type);
  //         });
  //     }
  // });

  /*
    PAYMENT CATEGORIES
    */
  // const { result: paymentCategoriesResult } =
  //     useGetAllPaymentCategoryTypesQuery();

  // watch(paymentCategoriesResult, () => {
  //     const result = paymentCategoriesResult.value;

  //     if (result) {
  //         result.paymentCategoryTypes.forEach((type) => {
  //             finance.value.paymentCategories.push(type);
  //         });
  //     }
  // });
  /*
    FINANCE TYPES (END)
    */

  /* AUCTION TYPES (START) */
  const auction = ref<{
    statusTypes: Auction_Status_Type[]
  }>({
    statusTypes: []
  })

  /* STATUS TYPE */
  const { result: auctionStatusTypesResult } = useGetAuctionStatusTypesQuery({
    limit: 999,
    offset: 0,
    orderBy: {
      type: Order_By.Asc
    }
  })

  watch(auctionStatusTypesResult, () => {
    const result = auctionStatusTypesResult.value
    if (result) {
      result.auctionStatusTypes.forEach((type) => {
        auction.value.statusTypes.push(type)
      })
    }
  })
  /* AUCTION TYPES (END) */

  return { vehicle, auction, inspection, appointment, finance, isLoading }
})

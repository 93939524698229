import type { Vehicle_Model } from '~/graphql/types'
import { useDatabaseTypes } from '~/pinia/database'
import _ from 'lodash'
import { biddingAxiosInstance } from '~/helpers/axios'

/**
 * Generates a vehicle name from vehicle model object
 * @param model Vehicle Model Object
 * @returns Vehicle Title in String
 */
export function getVehicleTitle(model: Vehicle_Model) {
  const databaseTypes = useDatabaseTypes()

  const information: string[] = []

  try {
    information.push(model.year)
    information.push(model.vehicleBrand.name)
    information.push(model.name)
    model.variant && model.variant != 'No variant' && information.push(model.variant)
    information.push(model.engine)
    information.push(
      databaseTypes.vehicle.transmissionTypes.find(
        (transmission) => transmission.type === model.transmission
      )?.comment || _.capitalize(model.transmission)
    )
  } catch (error) {
    console.warn(error)
  }

  return information.join(' ')
}

/**
 * Fetches the starting bid for a given vehicle auction.
 *
 * @param {string} vehicleAuctionId - The ID of the vehicle auction.
 * @returns {Promise<number>} - A promise that resolves to the starting bid amount.
 */
export async function getVehicleStartingBid(vehicleAuctionId: string) {
  const response = await biddingAxiosInstance.post('/starting-bid', {
      vehicleAuctionId
  });
  
  return response.data.data as number;
}

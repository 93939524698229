import type {
  Auction_Status_Type_Enum,
  Vehicle_Inspection,
  Vehicle_Model,
  Vehicle_Auction_Bid,
  Bid_Status_Type_Enum,
  Session_Status_Type_Enum
} from '~/graphql/types'
import type { Bid } from '~/types/bidding'

/**
 * Simple file interface
 */
export interface BaseFile {
  filename: string
  uri: string
}

/**
 * Recommendation interface
 */
export interface Recommendation {
  /**
   * Recommendation ID
   */
  id: string

  /**
   * Recommendation status
   */
  status: string

  /**
   * Vehicle price
   */
  price: number

  /**
   * Vehicle asked price
   */
  askedPrice?: number

  /**
   * Vehicle
   */
  vehicle: Vehicle
}

/**
 * Auction list item for catalog display
 */
export interface AuctionListItem {
  /**
   * Vehicle Auction ID
   */
  id: string

  /**
   * Vehicle Auction XID
   */
  xid: string

  /**
   * Start Date
   */
  startDate: Date

  /**
   * End Date
   */
  endDate: Date

  /**
   * Current bid count
   */
  bidCount: number

  /**
   * Starting bid
   */
  startingBid: number

  /**
   * Accepted bid from data patching or customer/broker approval
   */
  acceptedBid?: Bid

  /**
   * Fees for the successful bade auction
   */
  fees?: Fee

  /**
   * Auction Status
   */
  auctionStatus: Auction_Status_Type_Enum

  /**
   * User Auction Status
   */
  userAuctionStatus?: string

  /**
   * Status Group
   */
  statusGroup?: string

  /**
   * Ended auction approval status
   */
  approvalStatus?: Bid_Status_Type_Enum

  vehicle: Vehicle

  /**
   * Session ID of the auction
   */
  sessionId?: string

  /**
   * Session status
   */
  sessionStatus?: Session_Status_Type_Enum
}

/**
 * Auction interface
 */
export interface Auction extends AuctionListItem {
  /**
   * Current highest bid amount
   */
  highestBid?: number

  /**
   * Winning bid
   */
  winningBid?: Vehicle_Auction_Bid | undefined

  /**
   * Current unique users bid
   */
  uniqueUserCount?: number
}

/**
 * Fee
 */
export interface Fee {
  /**
   * Late fee (if userAuctionStatus is overdue)
   */
  lateFee?: number

  /**
   * Parking fee (if userAuctionStatus is overdue)
   */
  parkingFee?: number

  /**
   * Processing fee
   */
  processingFee?: number

  /**
   * Transportation fee
   */
  transportationFee?: number

  /**
   * SST (Tax)
   */
  sst?: number
  /**
   * Total amount of fees in vehicleAuctionFinance table
   */
  grandTotal?: number
}

/**
 * Auction Vehicle
 */
export interface Vehicle {
  /**
   * Vehicle ID
   */
  vehicleId?: string

  /**
   * Vehicle Plate No
   */
  vehicleNo?: string

  /**
   * Vehicle Brand Name.
   * Used in routes
   */
  brandName: string

  /**
   * Vehicle Title
   */
  title: string

  /**
   * State
   */
  inspectionCenterState: string

  /**
   * Current mileage
   */
  mileage: number

  /**
   * Vehicle Details
   */
  details?: string[]

  /**
   * Vehicle Image Links
   */
  images?: string[]

  /**
   * Vehicle Features
   */
  features?: string[]

  /**
   * Inspection center ID
   */
  inspectionCenterId?: string

  /**
   * Auction Owner ID
   */
  userOwnerId?: string

  /**
   * Vehicle Inspection ID
   */
  vehicleInspectionId?: string

  /**
   * Vehicle Inspection Details
   */
  vehicleInspectionDetails?: Vehicle_Inspection

  /**
   * Vehicle Model
   */
  vehicleModel?: Vehicle_Model
  [key: string]: any
}

/**
 * Interface for bidder in a simple list
 */
export interface BidderListItem {
  id: string
  name: string
  amount: number
  isBidByUser?: boolean
}

export enum NotificationTypeEnum {
  AUCTION_STARTED = 'Auction Started',
  AUCTION_ENDED = 'Auction Ended',
  BID_RECEIVED = 'Bid Received',
  BID_OVERTAKEN = 'Outbid Alert',
  BID_ACCEPTED = 'Bid Accepted',
  BID_REJECTED = 'Bid Rejected',
  BID_PLACED = 'Bid Placed',
  AUCTION_WON = 'Auction Won',
  AUCTION_LOST = 'Auction Lost'
}

export interface Notification {
  id: string
  type: NotificationTypeEnum
  metadata: {
    [key: string]: any
  }
  createdAt: Date
  status: 'PENDING' | 'READ'
}

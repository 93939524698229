import { createHttpLink, ApolloClient, InMemoryCache, split, ApolloLink } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from '@apollo/client/utilities'
import { createClient } from 'graphql-ws'
import { verifyToken } from 'carmine-auth-vue'
import * as Sentry from '@sentry/vue'

/**
 * Apollo HTTP Link
 */
const httpLink = createHttpLink({
  uri: import.meta.env.VUE_APP_HASURA_URL,
  credentials: 'same-origin'
})

const headers = () => {
  // Remove admin-secret & adds authorization header. Uncomment code below to enable this
  const auth = localStorage.getItem('auth')
  if (auth) {
    const { token } = JSON.parse(auth)
    // Check for token validity, valid if expirationUnix is more than current Unix
    const isTokenValid = verifyToken()
    if (typeof token === 'string' && token.length > 0 && isTokenValid) {
      return {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      }
    }
  }

  return {
    'x-hasura-role': 'anonymous',
    'Content-Type': 'application/json'
  }
}

/**
 * GraphQL Auth Link
 */
const authLink = setContext(async () => {
  return {
    headers: headers()
  }
})

/**
 * GraphQL Web-socket Link
 */
const wsLink = new GraphQLWsLink(
  createClient({
    url: import.meta.env.VUE_APP_HASURA_WS_URL,
    connectionParams: () => ({
      headers: headers(),
      lazy: true
    })
  })
)

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const scope = new Sentry.Scope()
  scope.setLevel('error')
  scope.setExtra('query', operation.query.loc?.source.body)
  scope.setExtra('variables', operation.variables)
  if (graphQLErrors) {
    Sentry.captureMessage(`[ErrorLink] GraphQL Error : ${graphQLErrors.map(error => error.message).join(', ')}`, scope)
  }
  if (networkError) {
    Sentry.captureMessage(`[ErrorLink] Network Error : ${networkError.message}`, scope)
  };
});

/**
 * Caching
 */
const cache = new InMemoryCache()

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    authLink,
    errorLink,
    split(
      ({ query }) => {
        const definition = getMainDefinition(query)
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
      },
      wsLink,
      httpLink
    ) as any
  ]),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      returnPartialData: false
    }
  }
})

import { createApp } from 'vue'
import { createPinia } from 'pinia'

// Sentry
import * as Sentry from '@sentry/vue'

import App from './App.vue'
import router from './router'
import VueScrollTo from 'vue-scrollto'
import { CUIPlugin } from 'carmine-ui/composables'
import VueTransitions from '@morev/vue-transitions'

// GraphQL
import { provideApolloClient, DefaultApolloClient } from '@vue/apollo-composable'
import { apolloClient } from '~/helpers/apollo'
import { createApolloProvider } from '@vue/apollo-option'

import 'vueperslides/dist/vueperslides.css'
import 'carmine-ui/css'
import './assets/main.css'
import '@morev/vue-transitions/styles'

// Auth
import auth from 'carmine-auth-vue'

const app = createApp(App)

Sentry.init({
  app,
  environment: import.meta.env.MODE,
  dsn: import.meta.env.VUE_APP_SENTRY_DSN,
  enabled: import.meta.env.MODE !== "development",
  // debug: true,
  tracePropagationTargets: [
    "localhost",
    "bidding.carmine.my",
    "dev-bidding.carmine.my",
  ],
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      routingInstrumentation: Sentry.vueRouterInstrumentation(router, {
        routeLabel: "path",
      }),
    }),
    new Sentry.Replay({
      maskAllText: false,
      maskAllInputs: false,
      mask: ['[type="password"]'],
    })
  ],
  sendDefaultPii: true,
  // Tracks child components
  trackComponents: true,
  // Performance Monitoring
  tracesSampleRate: 0.7, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

/*
GQL
*/
const apolloProvider = createApolloProvider({
  defaultClient: apolloClient
})

provideApolloClient(apolloClient)
app.provide(DefaultApolloClient, apolloClient)
app.use(apolloProvider)
/*
GQL
*/

app.use(createPinia())
app.use(auth, {
  baseURL: import.meta.env.VUE_APP_AUTH_SERVER_BASE_URL
})
app.use(router)
app.use(VueScrollTo)
app.use(CUIPlugin, {
  router,
  emailBaseUrl: import.meta.env.VUE_APP_EMAIL_SERVER_BASE_URL,
  imageKitUrl: import.meta.env.VUE_APP_IMAGEKIT_URL
})
app.use(VueTransitions)
app.mount('#app')

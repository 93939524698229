<script setup lang="ts">
import OutbidAlert from '~/components/layouts/notifications/OutbidAlert.vue'
import RecieveBidAlert from '~/components/layouts/notifications/RecieveBidAlert.vue'
import AuctionStartedAlert from '~/components/layouts/notifications/AuctionStartedAlert.vue'
import PlacedBidAlert from '~/components/layouts/notifications/PlacedBidAlert.vue'
import AuctionWonAlert from '~/components/layouts/notifications/AuctionWonAlert.vue'
import AuctionLostAlert from '~/components/layouts/notifications/AuctionLostAlert.vue'
import AuctionEndedAlert from '~/components/layouts/notifications/AuctionEndedAlert.vue'

// Directives
import { vIntersectionObserver } from '@vueuse/components'

// Composables
import { useUpdateNotificationStatusMutation } from '~/graphql/types'
import { useTimeoutFn } from '@vueuse/core'

// Helpers
import moment from 'moment'

// Types
import { NotificationTypeEnum, type Notification } from '~/types'

interface Props {
  notification: Notification
}

const props = defineProps<Props>()

/* UPDATE READ STATE */
const isNotificationSeen = ref(props.notification.status === 'READ')
const { start } = useTimeoutFn(
  () => {
    isNotificationSeen.value = true
  },
  2000,
  {
    immediate: false
  }
)
const { mutate } = useUpdateNotificationStatusMutation({
  variables: undefined
})

async function updateNotificationStatus([{ isIntersecting }]: any) {
  if (isIntersecting && !isNotificationSeen.value) {
    await mutate({
      id: props.notification.id,
      status: 'READ'
    })
    start()
  }
}
/* UPDATE READ STATE */
</script>

<template>
  <div class="flex flex-col relative" v-intersection-observer="updateNotificationStatus">
    <div class="flex flex-row items-center gap-x-2">
      <h6 class="text-left text-sm font-medium text-gray-900">{{ notification.type }}</h6>
      <p class="text-left text-xs font-normal text-gray-500">
        {{ moment(notification.createdAt).fromNow() }}
      </p>
    </div>
    <template v-if="notification.type === NotificationTypeEnum.BID_OVERTAKEN">
      <OutbidAlert :metadata="notification.metadata" />
    </template>
    <template v-if="notification.type === NotificationTypeEnum.BID_RECEIVED">
      <RecieveBidAlert :metadata="notification.metadata" />
    </template>
    <template v-if="notification.type === NotificationTypeEnum.AUCTION_STARTED">
      <AuctionStartedAlert :metadata="notification.metadata" />
    </template>
    <template v-if="notification.type === NotificationTypeEnum.BID_PLACED">
      <PlacedBidAlert :metadata="notification.metadata" />
    </template>
    <template v-if="notification.type === NotificationTypeEnum.AUCTION_WON">
      <AuctionWonAlert :metadata="notification.metadata" />
    </template>
    <template v-if="notification.type === NotificationTypeEnum.AUCTION_LOST">
      <AuctionLostAlert :metadata="notification.metadata" />
    </template>
    <template v-if="notification.type === NotificationTypeEnum.AUCTION_ENDED">
      <AuctionEndedAlert :metadata="notification.metadata" />
    </template>
    <!-- Marker -->
    <span
      v-if="!isNotificationSeen"
      class="h-2.5 w-2.5 absolute top-0 right-0 rounded-full bg-primary-600"
    ></span>
  </div>
</template>

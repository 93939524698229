<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionRoot, TransitionChild } from '@headlessui/vue'
import { Button, SvgIcon } from 'carmine-ui/components'
import NotificationAlertItem from '~/components/layouts/notifications/NotificationAlertItem.vue'

// Composables
import { useAuth } from 'carmine-auth-vue'
import {
  Order_By,
  useGetNotificationsQuery,
  useClearAllNotificationsMutation
} from '~/graphql/types'

// Helpers
import moment from 'moment'
import _ from 'lodash'
import { INJECTION_KEYS } from '~/helpers'

// Types
import { NotificationTypeEnum, type Notification } from '~/types'

// Icons
import CloseIcon from '~/assets/icons/x-close.svg'

interface Props {
  open?: boolean
}

const props = defineProps<Props>()

interface Events {
  (e: 'close'): void
  (e: 'fetchUnseenCount', value: number): void
}

const emits = defineEmits<Events>()

/* COMPOSABLE INSTANCES */
const auth = useAuth()
/* COMPOSABLE INSTANCES */

/* STATES */
const notificationGroup = ref<{
  [date: string]: Notification[]
}>({})
/* STATES */

/* GET NOTIFICATIONS */
const {
  result: notificationsResult,
  refetch,
  fetchMore
} = useGetNotificationsQuery(
  () => ({
    limit: 10,
    offset: 0,
    orderBy: {
      createdAt: Order_By.DescNullsLast
    },
    userId: auth.hasuraUser.value.id
  }),
  () => ({
    fetchPolicy: 'network-only',
    pollInterval: 5000,
    enabled: !!auth.isAuthenticated.value && !!auth.hasuraUser.value.id
  })
)

watch(notificationsResult, () => {
  const result = notificationsResult.value
  if (result && result.notifications.length > 0) {
    const transformed: Notification[] = result.notifications.map(
      (notification) =>
        ({
          id: notification.id,
          type: NotificationTypeEnum[notification.type],
          metadata: notification.metadata || {},
          createdAt: moment(notification.createdAt).toDate(),
          status: notification.status
        }) as Notification
    )
    notificationGroup.value = _.groupBy(transformed, (iteratee) => {
      return moment(iteratee.createdAt).format('DD MMM YYYY')
    })
    emits('fetchUnseenCount', result.totalUnseenCount.aggregate?.count || 0)
  }
})

function loadMore() {
  fetchMore({
    variables: {
      offset: notificationsResult.value?.notifications.length
    },
    updateQuery(previousQueryResult, { fetchMoreResult }) {
      // No new notifications
      if (!fetchMoreResult || fetchMoreResult.notifications.length === 0) return previousQueryResult

      return {
        ...previousQueryResult,
        notifications: [...previousQueryResult.notifications, ...fetchMoreResult.notifications]
      }
    }
  })
}
/* GET NOTIFICATIONS */

/* CLEAR NOTIFICATIONS */
const { mutate: clearAllNotifications } = useClearAllNotificationsMutation({
  variables: {
    userId: auth.hasuraUser.value.id
  }
})

async function clearNotifications() {
  await clearAllNotifications()
  emits('close')
}
/* CLEAR NOTIFICATIONS */

/* WATCHERS */
watch(
  () => props.open,
  () => {
    refetch()
  }
)
/* WATCHERS */

provide(INJECTION_KEYS.notification_drawer.close, () => emits('close'))
</script>

<template>
  <TransitionRoot :show="open" as="template">
    <Dialog class="relative" @close="emits('close')">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
        leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 z-[1] bg-gray-900/50 transition-all" aria-hidden="true" />
      </TransitionChild>
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="translate-x-full"
        enter-to="translate-x-none" leave="duration-200 ease-in" leave-from="translate-x-none"
        leave-to="translate-x-full">
        <DialogPanel
          class="fixed inset-y-0 inset-x-0 z-[2] tablet:right-0 tablet:left-auto tablet:w-96 flex flex-col px-4 py-6 bg-white overflow-y-auto">
          <div class="flex flex-row mb-6 justify-between items-center">
            <DialogTitle class="text-left text-xl font-medium text-gray-900">Notifications</DialogTitle>
            <div class="flex flex-row gap-x-4">
              <Button variant="text-gray" @click="clearNotifications">Clear All</Button>
              <Button variant="text-gray" @click="emits('close')">
                <SvgIcon :src="CloseIcon" :height="24" :width="24" class="stroke-2 stroke-gray-500" />
              </Button>
            </div>
          </div>
          <template v-if="Object.keys(notificationGroup).length > 0">
            <div v-for="(notifications, date, i) in notificationGroup" :key="date" class="flex flex-col gap-y-4">
              <!-- Date -->
              <p class="text-left text-xs font-normal text-gray-500">{{ date }}</p>
              <!-- Notification -->
              <TransitionGroup name="notifications-container">
                <NotificationAlertItem v-for="notification in notifications" :key="notification.id"
                  :notification="notification" />
              </TransitionGroup>
              <!-- Divider -->
              <div v-if="Object.keys(notificationGroup).length > 1 &&
                i !== Object.keys(notificationGroup).length - 1
                " class="my-8 h-px w-full bg-gray-200" />
            </div>
            <Button variant="primary" class="mt-8" @click="loadMore">Load More</Button>
          </template>
        </DialogPanel>
      </TransitionChild>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>
.notifications-container-enter-active,
.notifications-container-leave-active {
  transition: all 0.5s ease;
}

.notifications-container-enter-from,
.notifications-container-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>

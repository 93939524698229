<script setup lang="ts">
// Helpers
import { registerSwiper } from 'carmine-ui/components'

// Composables
import { useAuth } from 'carmine-auth-vue'
import { useNotification } from 'carmine-ui/composables'
import { useLocalStorage } from '@vueuse/core'
import { useDatabaseTypes } from '~/pinia/database'

// Helpers
import Idlejs from 'idle-js'
import moment from 'moment'
import * as Sentry from '@sentry/vue'

registerSwiper()

/**
 * Token expiration threshold to refresh, in minutes
 */
const TOKEN_EXPIRATION_THRESHOLD = 30
/**
 * Last active time duration, in hours
 */
const AWAY_TIME_DURATION = 12

/* COMPSABLE INSTANCES */
const { isLoading: isTypesLoading } = useDatabaseTypes()
const auth = useAuth()
const router = useRouter()
const notification = useNotification()

/**
 * Last active timestamp
 */
const lastActive = useLocalStorage<number>('last_active', 0)

/**
 * Token refreshing state
 */
const isTokenRefreshing = useLocalStorage<boolean>('is_token_refreshing', false)

watch(
  () => auth.isAuthenticated.value,
  async () => {
    if (!auth.isAuthenticated.value) {
      await auth.logout()
      Sentry.setUser(null)
      notification.warning({
        title: 'Session Expired',
        description: 'Your session has been expired. Please re-login to continue.'
      })
      await router.replace('/')
    } else {
      const user = auth.hasuraUser.value
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: user.id,
          username: user.name,
          email: user.email,
        })
      })
    }
  }
)

/**
 * Updates last active timestamp in localStorage
 */
function updateLastActive() {
  if (auth.isAuthenticated.value) {
    lastActive.value = moment().unix()
  } else {
    lastActive.value = 0
  }
}

/**
 * Checks last active time
 */
async function checkLastActive() {
  const lsAuth = localStorage.getItem('auth')
  if (lsAuth) {
    const { token, expirationUnix } = JSON.parse(lsAuth)
    const awayTime = moment.duration(moment().diff(moment.unix(lastActive.value))).asHours()
    const isLastActiveValid = awayTime < AWAY_TIME_DURATION
    // Check if user is currently logged in i.e has token in local storage, regardless of validity
    if (token && token !== '') {
      if (!isLastActiveValid) {
        await auth.logout()
        await router.replace('/')
        return false
      } else {
        // Refresh token if not authed or current token is almost over
        const isTokenExpiring =
          moment.duration(moment().diff(moment.unix(Number(expirationUnix)))).asMinutes() <
          TOKEN_EXPIRATION_THRESHOLD
        if ((!auth.isAuthenticated.value || isTokenExpiring) && isTokenRefreshing.value) {
          try {
            isTokenRefreshing.value = true
            await auth.refreshToken()
          } catch (error) {
            await auth.logout()
            notification.warning({
              title: 'Authentication failed',
              description:
                'There was an error refreshing your authentication. Please re-login to continue.'
            })
            await router.replace('/')
            return false
          } finally {
            isTokenRefreshing.value = false
          }
        }
      }
    }
  }
  return false
}

onMounted(async () => {
  checkLastActive()
  const idlejs = new Idlejs({
    onIdle() {
      updateLastActive()
    },
    onActive() {
      checkLastActive()
    }
  })
  idlejs.start()
  window.addEventListener('beforeunload', () => {
    updateLastActive()
  })
})
</script>

<template>
  <template v-if="isTypesLoading"> </template>
  <template v-else>
    <router-view />
  </template>
</template>

<style>
.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
</style>

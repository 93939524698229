<script setup lang="ts">
import { TabList } from '@headlessui/vue'

interface Props {
    mode: 'underline' | 'button' | 'underline-clear'
}

const { mode } = defineProps<Props>()

const containerStyle = {
    'underline-clear': 'border-b-[1px] border-b-gray-200',
    underline: 'bg-gray-25 border-b-[1px] border-b-gray-200',
    button: 'p-1 ring-1 ring-gray-100 ring-offset-0 gap-1 rounded-lg'
}

</script>

<template>
    <TabList :class="containerStyle[mode]" class="rounded-t-lg">
        <slot></slot>
    </TabList>
</template>
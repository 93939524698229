import axios from 'axios'

export const fileUploadAxiosInstance = axios.create({
  baseURL: import.meta.env.VUE_APP_FILE_UPLOAD_BASE_URL,
  headers: { 'Content-type': 'multipart/form-data' }
})

export const mailingAxiosInstance = axios.create({
  baseURL: import.meta.env.VUE_APP_EMAIL_SERVER_BASE_URL
})

export const biddingAxiosInstance = axios.create({
  baseURL: import.meta.env.VUE_APP_BIDDING_API_BASE_URL
})

export const biddingServerAxios = axios.create({
  baseURL: import.meta.env.VUE_APP_BIDDING_SERVER_BASE_URL
})

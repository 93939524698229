import type { NavigationObject } from 'carmine-ui/components'

export const CustomerNavigationList: NavigationObject[] = [
  {
    name: 'My Biddings',
    path: '/biddings'
  },
  {
    name: 'Bidding History',
    path: '/past-biddings'
  },
  {
    name: 'Saved Biddings',
    path: '/saved'
  }
]

export const DealerNavigationList: NavigationObject[] = [
  {
    name: 'Preview Biddings',
    path: '/preview'
  },
  {
    name: 'Marketplace',
    path: '/browse'
  },
  {
    name: 'Successful Bids',
    path: '/bids'
  },
  {
    name: 'Bidding History',
    path: '/bids-history'
  },
  {
    name: 'Saved Biddings',
    path: '/saved'
  }
]

<script setup lang="ts">
// Composables
import { useGetVehicleAuctionBidForNotficationQuery, type Vehicle_Model } from '~/graphql/types'
import { useNotificationDrawer } from '~/composables/notification'

// Helpers
import { getVehicleTitle } from '~/helpers/vehicle'
import { sluggify } from '~/helpers/string'

interface Props {
  metadata: any
}

const props = defineProps<Props>()

/* COMPOSABLES */
const notificationDrawer = useNotificationDrawer()
/* COMPOSABLES */

/* STATES */
const vehicleName = ref('')
const vehicleBrandName = ref('')
const vehicleNo = ref('')
const uri = ref()

const bidAmount = ref<number>()
/* STATES */

/* GET VEHICLE AUCTION */
const { onResult } = useGetVehicleAuctionBidForNotficationQuery({
  id: props.metadata.vehicle_auction_bid_id
})

onResult((param) => {
  const result = param.data
  if (result && result.vehicleAuctionBid) {
    const { vehicleAuction } = result.vehicleAuctionBid
    if (vehicleAuction.vehicleAuctionInspection?.vehicleModel) {
      vehicleName.value = getVehicleTitle(
        vehicleAuction.vehicleAuctionInspection?.vehicleModel as Vehicle_Model
      )
      vehicleBrandName.value = vehicleAuction.vehicleAuctionInspection?.vehicleModel.vehicleBrand.name
    }
    vehicleNo.value = vehicleAuction.vehicleNo
    nextTick(() => {
      uri.value = `/${sluggify(vehicleBrandName.value)}/${sluggify(vehicleName.value)}/${
        vehicleNo.value
      }`
    })
    bidAmount.value = result.vehicleAuctionBid.bidAmount
  }
})
/* GET VEHICLE AUCTION */
</script>

<template>
  <p class="text-left text-sm font-normal text-gray-500">
    Thank you for placing your bid of
    <span class="font-medium text-primary-600">RM {{ bidAmount?.toLocaleString('en-US') }}</span> on
    <RouterLink
      :to="uri || '#'"
      class="text-sm font-medium text-primary-600"
      @click="notificationDrawer.close"
      >{{ vehicleName }}</RouterLink
    >. Your bid has been received and processed. Good Luck!
  </p>
</template>

<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import {
  Button,
  SvgIcon,
  Navbar,
  NavbarHButton,
  Navlink,
  NavlinkContainer,
  Popover
} from 'carmine-ui/components'
import { TabGroup } from "@headlessui/vue";
import TabList from "~/components/tabs/TabList.vue";
import Tab from "~/components/tabs/Tab.vue";
import NotificationDrawer from '~/components/drawers/NotificationDrawer.vue'
import Logo from "~/components/Logo.vue";

// Composable
import { useToggle } from 'carmine-ui/composables'
import { useAuth } from 'carmine-auth-vue'

// Helper
import { CustomerNavigationList, DealerNavigationList } from '~/router/config'

// Types
import type { NavigationObject } from 'carmine-ui/components'

// Icons
import BellIcon from '~/assets/icons/bell-01.svg'
import LogoutIcon from '~/assets/icons/log-out-04.svg'

/* COMPOSABLE INSTANCE */
const auth = useAuth()
const router = useRouter()
const route = useRoute()

/**
 * Computes the navigation list based on the user's role,
 * including a hidden fallback tab at the start.
 * 
 * E.g. When we click on the link that is not part of the tab path, it will fallback to the hidden tab.
 */
const navigationList = computed(() => {
  const { role } = auth.hasuraUser.value

  // Define a hidden tab to act as the default fallback tab.
  const hiddenTab: NavigationObject = {
    name: '',
    path: '#'
  }

  switch (role) {
    case 'DEALER':
      return [hiddenTab, ...DealerNavigationList]
    default:
      return [hiddenTab, ...CustomerNavigationList]
  }
})

/* NOTIFICATIONS */
const { isShown: isNotificationDrawerShown, toggle: toggleNotificationDrawer } = useToggle()

/**
 * Unread notification count
 */
const unseenCount = ref<number>(0)

/**
 * Updates the unseen notification count state
 * Runs when NotificationDrawer emits fetch-unseen-count
 * @param value
 */
function updateUnseenNotificationCount(value: number) {
  unseenCount.value = value
}

/**
 * Starting index of the navigation list
 */
const startingIndex = computed(() => {
  const index = navigationList.value.findIndex((item) => item.path === route.path)

  return index < 0 ? 0 : index
})

/**
 * Navigates to the path of the selected tab index or defaults to the first tab if the selected index is invalid.
 * @param selectedIndex - The index of the selected tab.
 */
function navigateTo(selectedIndex: number) {
  const tabPath = navigationList.value[selectedIndex]?.path || navigationList.value[0]?.path;

  tabPath && router.push(tabPath);
}
</script>

<template>
  <Navbar class="border-b border-gray-200" navbar-container-class="justify-between">
    <div class="flex flex-row items-center gap-x-16">
      <Logo />
      <NavlinkContainer class="desktop:hidden">
        <Navlink v-for="link in navigationList" :key="link.name" :to="link.path || '#'" :children="link.children">{{
          link.name }}</Navlink>
      </NavlinkContainer>
    </div>
    <div class="flex flex-row items-center gap-x-4">
      <Popover placement="bottom-end" strategy="fixed" placement-strategy="none" slide hover>
        <div class="flex flex-row gap-x-2.5 items-center hover:bg-gray-50 desktop:px-3 desktop:py-2 rounded-lg">
          <div class="h-8 w-8 rounded-full overflow-hidden bg-gray-700">
            <img :src="auth.hasuraUser.value.image" class="h-full w-full object-center object-contain" />
          </div>
          <div class="hidden grow desktop:flex flex-col truncate">
            <span class="text-left text-sm font-medium text-gray-700">{{
              auth.hasuraUser.value.name
            }}</span>
            <span class="text-left text-xs font-normal text-gray-500">{{
              auth.hasuraUser.value.email
            }}</span>
          </div>
        </div>
        <template #content>
          <div class="py-5 rounded-lg bg-white border border-gray-200 drop-shadow w-80">
            <div class="px-8 py-3 hover:bg-gray-50 flex flex-row items-center gap-x-2 cursor-pointer" @click="
              router.push({
                name: 'manage-account'
              })
              ">
              <span class="text-left text-md font-medium text-gray-900">My Account</span>
            </div>
            <div class="px-8 py-3 hover:bg-gray-50 flex flex-row items-center gap-x-2 cursor-pointer"
              @click="auth.logout(() => router.push('/'))">
              <SvgIcon :src="LogoutIcon" class="stroke-2 stroke-error-500" />
              <span class="text-left text-md font-medium text-error-500">Logout</span>
            </div>
          </div>
        </template>
      </Popover>
      <Button variant="base" @click="toggleNotificationDrawer" class="relative">
        <SvgIcon :src="BellIcon" :height="24" :width="24" class="stroke-2 stroke-gray-500" />
        <div v-if="unseenCount > 0" class="flex text-xs font-medium text-white absolute -top-1/4 -right-1/4">
          <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary-500 opacity-75"></span>
          <span
            class="relative inline-flex items-center justify-center rounded-full min-h-[16px] min-w-[16px] bg-primary-600">{{
              unseenCount > 99 ? `99+` : unseenCount }}</span>
        </div>
      </Button>
      <NavbarHButton class="desktop:hidden" />
    </div>
  </Navbar>
  <TabGroup as="div" class="hidden desktop:block w-full" :selected-index="startingIndex" @change="navigateTo">
    <TabList mode="underline-clear">
      <div
        class="flex flex-row items-center justify-between mx-auto px-4 tablet:px-16 desktop:px-8 desktop:max-w-7xl h-full overflow-x-auto hide-scrollbar">
        <div class="flex flex-row justify-between">
          <template v-for="tab in navigationList">
            <Tab v-if="tab.path" mode="underline" class="whitespace-nowrap first:hidden" :key="tab.name" :tab="tab">
              {{ tab.name }}
            </Tab>
          </template>
        </div>
      </div>
    </TabList>
  </TabGroup>
  <NotificationDrawer :open="isNotificationDrawerShown" @close="toggleNotificationDrawer"
    @fetch-unseen-count="updateUnseenNotificationCount" />
</template>

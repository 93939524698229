import { createRouter, createWebHistory, type RouteLocationNormalized } from 'vue-router'
import HeaderPage from '~/components/layouts/HeaderPage.vue'

// Auth
import { useAuth } from 'carmine-auth-vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'root',
      component: HeaderPage,
      children: [
        {
          name: 'account',
          path: 'account',
          children: [
            {
              name: 'manage-account',
              path: '',
              component: () => import('~/pages/accounts/my-account.vue')
            }
          ]
        },
        {
          path: ':brand/:slug/:auctionXid',
          name: 'auction-details',
          component: () => import('~/pages/bidding/DetailsPage.vue'),
          meta: {
            auth: true
          },
          props(to) {
            return {
              auctionXid: to.params.auctionXid
            }
          }
        },
        {
          name: 'view-user-auctions',
          path: 'biddings',
          component: () => import('~/pages/auctions/user-auctions.vue'),
          meta: {
            auth: true,
            roles: ['BROKER', 'CUSTOMER']
          }
        },
        {
          name: 'past-biddings',
          path: 'past-biddings',
          component: () => import('~/pages/auctions/past-auctions.vue'),
          meta: {
            auth: true
          }
        },
        {
          name: 'preview-auctions',
          path: 'preview',
          component: () => import('~/pages/auctions/preview-auctions.vue'),
          meta: {
            auth: true,
            roles: ['DEALER']
          }
        },
        {
          name: 'browse-auctions',
          path: 'browse',
          component: () => import('~/pages/auctions/browse-auctions.vue'),
          meta: {
            auth: true,
            roles: ['DEALER']
          }
        },
        {
          name: 'successful-auctions',
          path: 'bids',
          component: () => import('~/pages/auctions/successful-auctions.vue'),
          meta: {
            auth: true,
            roles: ['DEALER']
          }
        },
        {
          name: 'auction-history',
          path: 'bids-history',
          component: () => import('~/pages/auctions/past-bid-auctions.vue'),
          meta: {
            auth: true,
            roles: ['DEALER']
          }
        },
        {
          name: 'saved-auctions',
          path: 'saved',
          component: () => import('~/pages/auctions/saved-auctions.vue'),
          meta: {
            auth: true
          }
        }
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('~/pages/authentications/log-in.vue'),
      meta: {
        noAuth: true
      }
    },
    {
      path: '/sign-up',
      name: 'sign-up',
      component: () => import('~/pages/authentications/sign-up.vue'),
      meta: {
        noAuth: true
      }
    },
    {
      path: '/forgot',
      name: 'forgot-password',
      component: () => import('~/pages/authentications/forgot-password.vue')
    },
    {
      path: '/change-password',
      name: 'change-password',
      component: () => import('~/pages/authentications/change-password.vue')
    },
    {
      path: '/reset/:payload',
      name: 'reset',
      component: () => import('~/pages/authentications/reset-password.vue')
    },
    {
      path: '/authorize',
      name: 'authorize',
      component: () => import('~/pages/authentications/authorize-login.vue')
    },
    {
      path: '/post-authorize',
      name: 'post-authorize',
      component: () => import('~/pages/authentications/post-authorize.vue')
    },
    {
      name: 'not-found',
      path: '/:path(.*)*',
      component: {
        created: () => {
          router.replace('/login')
        }
      }
    }
  ]
})

// Auth guard
router.beforeEach((to: RouteLocationNormalized) => {
  const auth = useAuth()
  const user = auth.hasuraUser.value

  // Authentication
  if (to.meta.auth && !auth.isAuthenticated.value) {
    auth.logout()
    // Unauthenticated and route needs auth
    const query = {}
    return {
      path: '/login',
      query
    }
  }

  if ((to.meta.noAuth && Boolean(auth.isAuthenticated.value)) || to.name === 'root') {
    // Authenticated
    const role = user.role || undefined

    // Redirect to their role-specific root
    switch (role) {
      case 'BROKER':
        return '/biddings'
      case 'CUSTOMER':
        return '/biddings'
      case 'DEALER':
        return '/preview'
      default:
        auth.logout()
        return '/login'
    }
  }

  // Check if route is valid for current role
  if (
    Boolean(auth.isAuthenticated.value) &&
    to.meta.roles &&
    !(to.meta.roles as string[]).includes(user.role)
  ) {
    return '/'
  }

  return true
})

export default router

/**
 * Vue Provide/Inject keys
 */
export const INJECTION_KEYS = {
  bookmark: {
    refetch: 'bookmark_refetch'
  },
  auction: {
    self: 'auction',
    refetch: 'auction_refetch',
    bids: {
      refetch: 'auction_bids_refetch',
      stats: {
        refetch: 'auction_bids_stats_refetch'
      }
    },
    session: {
      refetch: 'session_refetch',
      vehicles: {
        refetch: 'session_vehicles_refetch'
      }
    }
  },
  notification_drawer: {
    close: 'notification_drawer_close',
    open: 'notification_drawer_open'
  }
}

/**
 * Check whether an element is within visual boundary
 * @param id Element ID
 * @param offset
 * @returns boolean
 */
export const isElementWithinBounds = (id: string, offset?: number) => {
  const element = document.getElementById(id)

  if (element) {
    return (
      element.getBoundingClientRect().top <= 0 + (offset || 0) ||
      element.getBoundingClientRect().bottom <= 0 + (offset || 0)
    )
  }

  return undefined
}

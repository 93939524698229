<script setup lang="ts">
import { Tab } from '@headlessui/vue'
import { Button } from 'carmine-ui/components';
import type { NavigationObject } from 'carmine-ui/components'

interface Props {
    mode?: 'underline' | 'button' | 'underline-clear' | 'clear',
    align?: 'start' | 'end' | 'center',
    disabled?: boolean,
    headless?: boolean,
    tab?: NavigationObject
}

const { mode = 'underline', align, disabled, headless } = defineProps<Props>()

const buttonStyle = {
    underline: "hover:border-b-2 hover:border-b-primary-700 hover:text-primary-700",
    button: "hover:bg-primary-600 hover:text-white rounded-md border-0 ",
    clear: "hover:bg-white rounded-md ring-gray-200"
}

const buttonStyleFunc = (mode, selected, disabled) => {
    let style = '';

    if (selected == true) {
        switch (mode) {
            case 'button':
                style = '!text-white bg-primary-600 ';
                break;
            case 'clear':
                style = '!text-gray-700 bg-white shadow-md ring-1';
                break;
            default:
                style = '!text-primary-700 !border-b-primary-700 border-b-2 ';
                break;
        }
    }

    if (disabled === true) {
        style += 'cursor-not-allowed ';
    }

    return style += buttonStyle[mode];
}

function justifyContent() {
    switch (align) {
        case 'start':
            return '!justify-start'
        case 'end':
            return '!justify-end'
        case 'center':
            return '!justify-center'
        default:
            return '!justify-start'
    }
}

</script>

<template>
    <Tab as="div" class="outline-none cursor-pointer" v-slot="{ selected }" :disabled="disabled">
        <!-- Underline -->
        <template v-if="!headless">
            <div :class="[buttonStyleFunc(mode, selected, disabled), justifyContent()]"
                class="w-full px-3 py-5 outline-none outline-2 text-sm font-medium text-gray-500">
                <slot></slot>
            </div>
        </template>
        <template v-else>
            <slot :selected="selected"></slot>
        </template>
    </Tab>
</template>
import _ from 'lodash'

/**
 * Converts a white-spaced string of words into a slug (URL encoded)
 * @param string
 * @returns
 */
export const sluggify = (string: string) => {
  return encodeURIComponent(string.trim().replace(/ /g, '-').toLowerCase())
}

/**
 * Capitalizes each word separated by a string
 * @param string String to be capitalized
 * @param separator Separator (default: " ")
 * @returns Capitalized string
 */
export const capitalizeEachWord = (string: string, separator?: string) => {
  return string
    .split(separator || ' ')
    .map((word) => _.capitalize(word))
    .join(' ')
}
